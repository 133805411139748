import React from "react";
import Layout from "../components/Layout";

const NotFound = () => {
  if (typeof window !== 'undefined') {
    window.location = '/';
  }

  return (
    <Layout>
      These were Index Page;
    </Layout>
  );
};

export default NotFound;